<template>
  <div>
    <title-nav :title="'공지'" :nav="'CS / 공지'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-input-group size="sm" prepend="공지 종류" class="w-25">
            <b-form-select
              size="sm"
              v-model="noti_type"
              :options="options"
              class="w-25"
            ></b-form-select>
          </b-input-group>
          <b-input-group size="sm" prepend="공지 제목" class="w-50 pt-1 pb-1">
            <b-form-input size="sm" v-model="noti_title" :options="options" class=""></b-form-input>
          </b-input-group>
          <b-form-textarea
            v-model="contents"
            id="noti-contents"
            placeholder="내용을 입력하세요"
            rows="10"
            max-rows="10"
            required
            wrap="hard"
          />
          <b-form-group label="제휴사 노출여부">
            <b-form-checkbox-group v-model="storeCd">
              <b-form-checkbox value="F">FMschool</b-form-checkbox>
              <b-form-checkbox value="P">Plus-up</b-form-checkbox>
              <b-form-checkbox value="E">Edaily</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <b-button @click="cancel" class="m-1">
            취소
          </b-button>
          <template v-if="isEditMode">
            <b-button @click="updateNotice" class="m-1" variant="primary">
              수정
            </b-button>
            <b-button @click="deleteNotice" variant="danger">삭제 </b-button>
            <b-button @click="pushNotice" variant="success" class="m-1 float-right"
              >앱 푸시
              <span class="badge badge-secondary">{{ pushCount }}</span>
            </b-button>
          </template>
          <template v-else>
            <b-button @click="createNotice" class="m-1" variant="primary">
              등록
            </b-button>
          </template>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:cell(reg_dt)="data">
              {{ data.value | dateFilter }}
            </template>
            <template v-slot:cell(notice_sn)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(notice_type)="data">
              {{ getCodeName(data.item.notice_type) }}
            </template>
            <template v-slot:cell(custom_edit)="data">
              <b-button variant="link" @click="fillData(data.item)">수정/내용보기 </b-button>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
    <!-- 삭제 모달창 -->
    <b-modal ref="modal-delete" hide-footer title="정말 삭제하시겠습니까?">
      <b-button class="mt-3" variant="outline-danger" block @click="requestDelete">삭제</b-button>
      <b-button class="mt-2" variant="outline-warning" block @click="closeModal">아니요</b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  getNoticeList,
  getNoticeDetail,
  getNoticeCode,
  createNotice,
  updateNotice,
  deleteNotice,
  pushNotice
} from '@/api/cs'
import Editor from '../util/Editor'
export default {
  name: 'NoticeList',
  components: { Editor },
  data() {
    return {
      page: 1,
      rowPerPage: 10,
      records: 0,
      isBusy: false,
      noti_type: '',
      noti_title: '',
      noti_code: null,
      noti_sn: '',
      options: [],
      isEditMode: false,
      fields: [
        {
          key: 'notice_sn',
          label: 'No.',
          colType: 'span'
        },
        {
          key: 'reg_dt',
          label: '등록일시',
          colType: 'span'
        },
        {
          key: 'notice_type',
          label: '공지 타입',
          colType: 'span'
        },
        {
          key: 'title',
          label: '공지 제목',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'store_cds',
          label: '제휴사',
          colType: 'span'
        },
        {
          key: 'custom_edit',
          label: '수정/내용보기',
          colType: 'span'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      contents: '',
      qna_sn: '',
      itemsDetail: '',
      answer: '',
      pushCount: 0,
      storeCd: []
    }
  },
  methods: {
    async requestDelete() {
      const response = await deleteNotice(this.noti_sn)
      if (response.code === '0000') {
        this.getList()
        alert('삭제 되었습니다.')
      } else {
        alert(response.message)
      }
      this.$refs['modal-delete'].hide()
    },
    closeModal() {
      this.$refs['modal-delete'].hide()
    },
    fillData(item) {
      this.noti_type = item.notice_type
      this.noti_title = item.title
      this.noti_sn = item.notice_sn
      this.storeCd = item.store_cds.split(',')
      this.isEditMode = true

      getNoticeDetail(item.notice_sn).then(resp => {
        this.contents = resp.data.contents
        this.pushCount = resp.data.push_count
      })
    },
    deleteNotice() {
      this.$refs['modal-delete'].show()
    },
    getCodeName(code) {
      if (code && this.noti_code) {
        const item = this.noti_code.find(value => value.cd_value === code)
        return item.cd_name_kr
      }
      return ''
    },
    async updateNotice() {
      if (this.noti_sn && this.noti_type && this.noti_title && this.contents) {
        const response = await updateNotice(
          this.noti_sn,
          this.noti_type,
          this.noti_title,
          this.contents,
          this.storeCd.join(',')
        )
        if (response.code === '0000') {
          alert('반영되었습니다.')
          this.cancel()
          this.getList()
        } else {
          alert('반영 실패하였습니다. ' + response.message)
        }
      } else {
        alert('데이터를 모두 입력하세요')
      }
    },
    async createNotice() {
      if (this.noti_type && this.noti_title && this.contents) {
        const response = await createNotice(
          this.noti_type,
          this.noti_title,
          this.contents,
          this.storeCd.join(',')
        )
        if (response.code === '0000') {
          alert('등록되었습니다.')
          this.getList()
        } else {
          alert(response.message)
        }
      } else {
        alert('모두 입력해주세요')
      }
    },
    cancel() {
      this.isEditMode = false
      this.noti_type = null
      this.noti_title = null
      this.contents = null
      this.storeCd = []
    },
    async getList() {
      this.setNoticeType()
      this.isBusy = true
      const response = await getNoticeList(this.page, this.rowPerPage)

      this.items = response.data.list
      this.records = response.data.totCnt
      this.isBusy = false
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    setCondition() {
      this.page = 1
      this.getList()
    },
    async getDetail(num) {
      const response = await getNoticeDetail(num)
      this.noti_type = response.data.notice_type
      this.noti_title = response.data.title
      this.contents = response.data.contents
      // this.answer = this.itemsDetail.ANSWER
      this.pushCount = response.data.push_count
    },
    async setNoticeType() {
      const response = await getNoticeCode()
      if (response.code === '0000') {
        this.noti_code = response.data
        response.data.forEach(value => {
          if (value.cd_value !== '*') {
            this.options.push({
              value: value.cd_value,
              text: value.cd_name_kr
            })
          }
        })
      }
    },
    async pushNotice() {
      const response = await pushNotice(this.noti_type, this.noti_title, this.noti_sn)
      if (response.code === '0000') {
        //this.getDetail(this.noti_sn)
        this.$router.push({ path: '/cs/push', query: { notice_sn: this.noti_sn } })
      }
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  padding-left: 10px;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: left;
  padding-left: 10px;
}
</style>
